import styled from 'styled-components'

export const LabelStyle = styled.div`
  border-radius: 8px;
  width: ${(props) => props.width};
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  color: ${(props) => props.theme.color[props.color]};
  background: ${(props) => props.theme.color[props.background]};
  border: 1px solid ${(props) => props.theme.color[props.border]};
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
