import styled from 'styled-components'

export const CardStyle1 = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.theme.color.primary};
  border-radius: 8px;
  position: relative;
  padding: ${(props) => props.padding};
`

export const CardStyle2 = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.theme.color[props.background]};
  border: 1px solid ${(props) => props.theme.color[props.borderColor]};
  color: ${(props) => props.theme.color[props.color]};
  border-radius: 8px;
  position: relative;
  padding: ${(props) => props.padding};
`
