// Parse and stringify URL query strings
import { stringify } from "query-string";
import api from "../index";

export const scheduleController = () => {
  return {
    getSchedule: async ({ limit, page = "", startDate, endDate }) => {
      const params = stringify({ limit, page, startDate, endDate });
      if (startDate || endDate !== "")
        return await api.get(`/schedules?${params}`);
    },
    getScheduleById: async (id) => {
      return await api.get(`/schedules/${id}`);
    },
    createSchedule: async (data) => {
      return await api.post(`/schedules`, data);
    },
    updateScheduleById: async ({ id, data }) => {
      return await api.patch(`/schedules/${id}`, data);
    },
    deleteScheduleById: async (id) => {
      return await api.delete(`/schedules/${id}`);
    },
    searchSchedule: async ({ limit, page = "", keyword = "" }) => {
      const params = stringify({ limit, page, keyword });
      if (keyword !== "") return await api.get(`/schedules/keyword?${params}`);
    },
  };
};
