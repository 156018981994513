// Parse and stringify URL query strings
import { stringify } from "query-string";
import api from "../index";

export const transportController = () => {
  return {
    getTransport: async ({ limit, page = "" }) => {
      const params = stringify({ limit, page });
      return await api.get(`/transports?${params}`);
    },
    createTransport: async (data) => {
      return await api.post(`/transports`, data);
    },
    updateTransportById: async ({ id, data }) => {
      return await api.patch(`/transports/${id}`, data);
    },
    deleteTransportById: async (id) => {
      return await api.delete(`/transports/${id}`);
    },
    searchTransport: async ({ limit, page = "", keyword = "" }) => {
      const params = stringify({ limit, page, keyword });
      if (keyword !== "") return await api.get(`/transports/keyword?${params}`);
    },
  };
};
