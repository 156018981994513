import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";
import { LabelStyle } from "./style";
import { Tooltip } from "antd";

export const Label = ({ className, children, background, border, color, title, width, padding, onClick }) => {
  const classname = clsx({ [className]: className });
  return (
    <Tooltip title={title}>
      <LabelStyle
        background={background}
        border={border}
        color={color}
        className={classname}
        width={width}
        padding={padding}
        onClick={onClick}
      >
        {children}
      </LabelStyle>
    </Tooltip>
  );
};

const defaultProps = {
  title: "prompt text",
  background: "secondary",
  color: "white",
  border: "secondary",
  width: "fit-content",
  padding: "1rem 1rem",
  children: null,
};

const propTypes = {
  title: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Label.defaultProps = defaultProps;
Label.propTypes = { ...propTypes, title: PropTypes.string };
