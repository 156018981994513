import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { CardStyle1, CardStyle2 } from "./style";

export const CardStylePrimary = ({
  className,
  type,
  width,
  height,
  padding,
  children,
}) => {
  const classname = clsx({ [type]: type, [className]: className });
  return (
    <CardStyle1
      className={classname}
      width={width}
      height={height}
      padding={padding}
    >
      {children}
    </CardStyle1>
  );
};

export const CardStyleSecondary = ({
  className,
  type,
  width,
  height,
  padding,
  background,
  borderColor,
  color,
  children,
}) => {
  const classname = clsx({ [type]: type, [className]: className });
  return (
    <CardStyle2
      className={classname}
      width={width}
      height={height}
      padding={padding}
      background={background}
      borderColor={borderColor}
      color={color}
    >
      {children}
    </CardStyle2>
  );
};

CardStylePrimary.defaultProps = {
  width: "100%",
  height: "100%",
  padding: "2rem 2rem",
  type: "normal",
};

CardStylePrimary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["bottom", "normal"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
};

CardStyleSecondary.defaultProps = {
  width: "100%",
  height: "100%",
  padding: "2rem 2rem",
  type: "normal",
  color: "black"
};

CardStyleSecondary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.oneOf(["bottom", "normal"]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
};
